class Particle {
    // constructor(x, y, dx, dy, width, ctx, mouse) {
    //   this.x = x;
    //   this.y = y;
    //   this.dx = dx;
    //   this.dy = dy;
    //   this.ctx = ctx;
    //   this.width = width;
    //   this.minWidth = width;
    //   this.maxWidth = width * 3;
    //   this.mouse = mouse;

    //   // let colorArray = ["#de3d3d", "#090c0b", "#0d2527", "#267368", "#00b1a0"];
    //   let colorArray = ["#de3d3d", "#090c0b"];

    //   this.color = colorArray[Math.floor(Math.random() * colorArray.length)];
    // }

    constructor() {
        // TODO move all this constructor logic in here
        let width = Math.random() * 5 + 4;
        let x = Math.random() * window.innerWidth;
        let dx = (Math.random() - 0.5) * 1;
        let y = Math.random() * window.innerHeight;
        let dy = (Math.random() - 0.5) * 1;
        // let dy = (Math.random()-0.5)*20;

        // this.vx = (Math.random()-0.5)*20;
        // this.vy = (Math.random()-0.5)*20;

        this.x = x;
        this.y = y;
        this.dx = dx;
        this.dy = dy;
        // this.ctx = ctx;
        this.width = width;
        this.minWidth = width;
        this.maxWidth = width * 2;
        // this.mouse = mouse;

        let colorArray = ["#778899", "#2F4F4F"];
        // let colorArray = ["#de3d3d", "#090c0b", "#0d2527", "#267368", "#00b1a0"];
        // let colorArray = ["#de3d3d", "#090c0b"];

        this.color = colorArray[Math.floor(Math.random() * colorArray.length)];
    }

    draw = (ctx, mouse) => {
        ctx.beginPath();
        ctx.moveTo(this.x, this.y);
        // ctx.arc(100, 75, 50, 0, 2 * Math.PI);
        // ctx.stroke();
        ctx.lineTo(this.x - this.width / 5, this.y);
        ctx.lineTo(this.x, this.y + this.width / 5);
        ctx.lineTo(this.x + this.width / 5, this.y);
        ctx.lineTo(this.x, this.y - this.width / 5);
        ctx.lineTo(this.x - this.width / 5, this.y);
        ctx.closePath();

        ctx.fillStyle = this.color;
        ctx.fill();

        // window.console.log("inside draw");
        this.update(mouse);
    };

    update = (mouse) => {
        if (
            this.x + this.width / 2 >= window.innerWidth ||
            this.x - this.width / 2 <= 0
        ) {
            this.dx = -this.dx;
        }

        if (
            this.y + this.width / 2 >= window.innerHeight ||
            this.y - this.width / 2 <= 0
        ) {
            this.dy = -this.dy;
        }

        this.x += this.dx;
        this.y += this.dy;

        // interactivity
        if (
            mouse.x - this.x < 80 &&
            mouse.x - this.x > -80 &&
            mouse.y - this.y < 80 &&
            mouse.y - this.y > -80 &&
            this.width < this.maxWidth
        ) {
            this.width += 1;
            this.x -= 1;
            this.y -= 1;
        } else if (this.width > this.minWidth) {
            this.width -= 1;
            this.x += 1;
            this.y += 1;
        }
    };

}

  export default Particle;