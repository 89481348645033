import React, { Component } from 'react';
import Formsy from 'formsy-react';
import axios from "axios";

import InputField from './InputField';
import './ContactForm.css';

export default class ContactForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            enableButton: false,
            validationErrors: {}
        };
        this.handleSendEmail = this.handleSendEmail.bind(this);
        this.handleOnValid = this.handleOnValid.bind(this);
        this.handleOnInvalid = this.handleOnInvalid.bind(this);
    }

    /**
     * This handler enables the submit button when the form is valid
     */
    handleOnValid() {
        this.setState({
            enableButton: true
        });
    }

    /**
     * This handler disabled the submit button when the form is invalid
     */
    handleOnInvalid() {
        this.setState({
            enableButton: false
        });
    }

    /**
     * This handler sends the form data to the ATLAS API to handle sending the email
     * @param values: the form values
     */
    handleSendEmail(values) {
        axios.defaults.baseURL = process.env.REACT_APP_ATLAS_API;
        axios.post("/about/contact/", values)
            .then((response) => {
                this.setState({
                    emailSent: true,
                    showEmailAlert: true,
                })
            })
            .catch((error) => {
                window.console.log(error);
                this.setState({
                    emailSent: false,
                    showEmailAlert: true,
                    errorMessage: "Error sending message, please try again later: " + error.message
                });
            });
    }

    render() {
        const alert = (
            <div>
                { this.state.emailSent ?
                    <div className="alert alert-success" role="alert">Message successfully sent!</div> :
                    <div className="alert alert-danger" role="alert">{ this.state.errorMessage }</div>
                }
            </div>
        );
        return (
            <div id="contact-form" className="container">
                <h2><i className="fas fa-at" /> Contact Me</h2>
                { this.state.showEmailAlert && alert }
                <Formsy onSubmit={ this.handleSendEmail } validationErrors={ this.state.validationErrors }
                        onValid={ this.handleOnValid } onInvalid={ this.handleOnInvalid }>
                    <InputField name="name" title="Name" type="text" required />
                    <InputField name="email" title="Email" type="text"
                                validations="isEmail" validationError="This is not a valid email" required />
                    <InputField name="subject" title="Subject" type="text" required />
                    <InputField name="message" title="Message" type="textarea" required />
                    <button type="submit" className="btn btn-primary btn-sm" disabled={ !this.state.enableButton }>Submit</button>
                </Formsy>
            </div>
        );
    }
}