import React, { Component } from 'react';
// import axios from "axios";
import { atlasAPI } from "../services";

import Section from './Section';

export default class PortfolioSection extends Component {

    constructor(props) {
        super(props);
        this.state = {
			loaded: false,
			projects: []
        };
    }

    /**
     * Make a GET request out to the ATLAS API service to get the featured projects
     */
    componentDidMount() {
        atlasAPI.get("projects/featured/")
            .then((response) => {
                this.setState({
                    projects: response.data,
                    loaded: true,
                    hasError: false
                })
            })
            .catch((error) => {
                this.setState({
                    loaded: true,
                    hasError: true,
                    errorMessage: "Unexpected error getting featured projects: " + error.message
                });
            });
    }

    /**
     * Responsible for rendering the featured project details returned from the REST service or the
     * error message if one is encountered
     * @returns {XML}
     */
    renderFeaturedProjects() {
        return (
            <>
                { this.state.hasError ?
                    <div className="alert alert-danger" role="alert">{ this.state.errorMessage }</div> :
                    this.state.projects.map((project) => {
                        return (
                            <div key={ project.id } className="col-sm-4 col-xs-12 project-main-photo">
                                <div className="hovereffect">
                                    <img className="img-responsive" src={ project.main_photo } alt={ project.name } height="300" width="400" />
                                    <div className="overlay">
                                        <h2>{ project.name }</h2>
                                        <p>{ project.tech_stack_display }</p>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </>
        );
    }

    /**
     *  Responsible for building the featured project's section on the home page
     * @returns {XML}
     */
    render() {
        return (
            <Section id="portfolio-section" title="Featured Projects">
                { !this.state.loaded ?
                    <div className={"row justify-content-center"}><i className="fa fa-spinner fa-pulse fa-3x fa-fw" /></div> :
                    this.renderFeaturedProjects()
                }
            </Section>
        );
    }
}