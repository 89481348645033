import React from "react";
import { Row, Container, Col } from "reactstrap";

import BashShell from '../common/bash/BashShell';
import SkillSection from './SkillSection';
import PortfolioSection from "./PortfolioSection";
import ExpertiseSection from "./ExpertiseSection";

import Particle from "./particles/Particle";
import Canvas from './Canvas';
import profileImage from "../assets/img/profile-1.jpeg";

import "./Home.css";
import "./particles/particles.css";


const drawParticles = (ctx, mouse, _frameCount, stars) => {
    ctx.clearRect(0, 0, window.innerWidth, window.innerHeight);
    stars.forEach((star) => {
        star.draw(ctx, mouse);
    });
};

// TODO: create a way for my code to reach out to me if code is not running on my domain. This has to be obfuscated.
const Home = () => {
    const numParticles = 400;
    let particles = [];
    for (let i = 0; i < numParticles; i++) {
        particles.push(new Particle());
    }
    // TODO: add a banner messaging system that reads messages that I post in the API

    // TODO: have the variable accept a comma separated list and the JS converts that to an array and get a random value every time
    return (
        <div className="mt-5">
            <Canvas id="particles-canvas" draw={drawParticles} objects={particles} />
            <div id="landing-page-content">
                <section id="intro-section">
                    <Container>
                            <Row>
                                <Col>
                                    <div id="intro-photo" className="mb-3">
                                        <img className="img-circle" src={profileImage} alt="alex olivas profile" width="200" height="200" />
                                    </div>
                                </Col>
                            </Row>
                            <Row className="mt-2">
                                <Col>
                                    <BashShell text="Senior Software Engineer" />
                                </Col>
                            </Row>
                            <Row className="mt-4">
                                <Col>
                                    <div className="ml-5 mr-5">
                                        <p>Software engineer with a degree in Computer Science that specializes in building complex scalable web applications, networking programming, and algorithms. I have experience working across various industries like healthcare, telecommunications, music, education, and cyber security.</p>
                                    </div>
                                </Col>
                            </Row>
                    </Container>
                    {/* <div id="scroll-notifier" className="text-center">
                        <button type="button" class="btn btn-link"><i class="fas fa-arrow-circle-down fa-3x animated bounce infinite"></i></button>
                    </div> */}
                </section>
            </div>
            <ExpertiseSection/>
            <SkillSection/>
            <PortfolioSection/>
        </div>
    );
}

export default Home;