import React  from "react";
import { Col } from "reactstrap";

import expertise from "./__mocks__/expertise.json";
import Section from "./Section";

/**
 * Responsible for building the technical expertise section on the home page
 */
const ExpertiseSection = () => (
    <Section id="expertise-section" title="Expertise">
        { expertise.map((curr, index) => (
            <Col key={ index } sm={3} xs={12}>
                <h4>
                    <i className={`fa ${ curr.fontAwesomeIcon } light-blue hover-icon small-icon sonar-effect`} />
                    {" "}
                    { curr.area }
                </h4>
                <span>{ curr.description }</span>
            </Col>
        ))}
    </Section>
);

export default ExpertiseSection;
