import React, { useEffect, useRef } from "react";

// https://medium.com/@pdx.lucasm/canvas-with-react-js-32e133c05258

// TODO: create a library for this
const Canvas = (props) => {
	// Objects is the initialized objects if they have state and it should be optional
	const { draw, objects, ...rest } = props
	const canvasRef = useRef(null)

	useEffect(() => {
	  	const canvas = canvasRef.current
	  	const context = canvas.getContext('2d')

		canvas.width = window.innerWidth;
		canvas.height = window.innerHeight;

		window.addEventListener("resize", function() {
			canvas.width = window.innerWidth;
			canvas.height = window.innerHeight;
		});

		let mouse = {
			x: undefined,
			y: undefined
		};

		window.addEventListener("mousemove", function(e) {
			mouse.x = e.x;
			mouse.y = e.y;
		});


	  let frameCount = 0
	  let animationFrameId
	  const render = () => {
		frameCount++
		draw(context, mouse, frameCount, objects)
		animationFrameId = window.requestAnimationFrame(render)
	  }
	  render()

	  return () => {
		window.cancelAnimationFrame(animationFrameId)
	  }
	}, [draw, objects])

	return <canvas ref={canvasRef} {...rest}/>
};

export default Canvas;