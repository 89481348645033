import classNames from "classnames";
import React, { Component } from "react";
// import Slide from "react-reveal/Slide";
// import Menu from "./Menu";
import { Container, Nav, Navbar, NavbarBrand } from 'reactstrap';

import './Header.css';

/**
 * This component represents the website's header, containing the logo and navigation menu
 */
class Header extends Component {

    constructor(props) {
        super(props);
        this.state = {
            lightMode: false,
            reveal: false,
        };
    }

    componentDidMount() {
        window.addEventListener("scroll", (e) => this.handleScroll(e));
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", (e) => this.handleScroll(e));
    }

    handleScroll(event) {
        const currY = window.pageYOffset;
        // const windowHeight = window.innerHeight;
        if ( currY > 150 && !this.state.lightMode ) {
            this.setState({
                lightMode: true,
                reveal: true,
            });
        }
        if ( currY <= 150 && this.state.lightMode ) {
            this.setState({
                lightMode: false,
                reveal: false,
            });
        }
    }

    render() {
        // const { lightMode, reveal } = this.state;
        const { lightMode } = this.state;
        // const cssClasses = classNames({
        //     // "navbar": true,
        //     // "fixed-top": true,
        //     "navbar-expand-lg": true,
        //     // "navbar-light": lightMode,
        //     "bg-light": lightMode,
        //     "navbar-dark": !lightMode,
        // });
        // return (
        //     <header>
        //         {/* <Slide top spy={ reveal } opposite={ true } mirror={ !reveal }> */}
        //             {/* <nav className="navbar fixed-top navbar-expand-lg navbar-dark"> */}
        //             <Navbar dark fixed="top">
        //                 <Menu items={ this.props.menuItems }/>
        //             </Navbar>
        //             {/* <nav className={ cssClasses }>
        //                 <Menu items={ this.props.menuItems }/>
        //             </nav> */}
        //         {/* </Slide> */}
        //     </header>
        // );

        const cssClasses = classNames({
            // "navbar": true,
            // "fixed-top": true,
            // "navbar-expand-lg": true,
            "navbar-light": lightMode,
            "bg-light": lightMode,
            "navbar-dark": !lightMode,
        });
        // return (
        //     <header>
        //         <Slide top spy={ reveal } opposite={ true } mirror={ !reveal }>
        //             <nav className={ cssClasses }>
        //                 <div className="container">
        //                     {/* { lightMode &&
        //                         <a className="navbar-brand" href="/">
        //                             <VerdeBistroLogo small />
        //                         </a>
        //                     } */}
        //                     <button className="navbar-toggler" type="button"
        //                         data-toggle="collapse" data-target="#navigation-menu"
        //                         aria-controls="navigation-menu" aria-expanded="false" aria-label="Toggle navigation">
        //                         <span className="navbar-toggler-icon" />
        //                     </button>
        //                     <div className="collapse navbar-collapse" id="navigation-menu">
        //                         <ul className="navbar-nav ml-auto">
        //                             {/* { menuItems } */}
        //                             <Menu items={ this.props.menuItems }/>
        //                         </ul>
        //                     </div>
        //                 </div>
        //             </nav>
        //         </Slide>
        //     </header>
        // );
        return (
            <header>
                {/* <Slide top spy={ reveal } opposite={ true } mirror={ !reveal }> */}
                    <Navbar fixed="top" className={ cssClasses }>
                        <Container>
                            <NavbarBrand href="/">
                                {/* <Row>
                                    <Col>
                                        <img className="img-circle" src="https://media-exp1.licdn.com/dms/image/C4D03AQHEyYaJoWWY5A/profile-displayphoto-shrink_400_400/0?e=1599696000&v=beta&t=NwJ8oVHDA-Dy-skNG6Pn1SkRWHCRIzDPHhFfZWIk8yw" alt="alex olivas profile" width="50" height="50" />
                                    </Col>
                                    <Col>
                                        <label>alex</label><label style={{ color: "#00b3ee" }}>.</label><label>olivas</label><BashShell text="Software Engineer" />
                                    </Col>
                                </Row> */}
                                {/* <img className="img-circle" src="https://media-exp1.licdn.com/dms/image/C4D03AQHEyYaJoWWY5A/profile-displayphoto-shrink_400_400/0?e=1599696000&v=beta&t=NwJ8oVHDA-Dy-skNG6Pn1SkRWHCRIzDPHhFfZWIk8yw" alt="alex olivas profile" width="50" height="50" /> */}
                                {/* <i className="fa fa-cube menu-item-spacing" /> */}
                                <i className="fas fa-terminal menu-item-spacing"></i>
                                {" "}
                                Alex Olivas
                            </NavbarBrand>
                            {/* <NavbarText>
                                <BashShell text="Software Engineer" />
                            </NavbarText> */}
                            <Nav>
                                {/* { this.props.menuItems.map((item, index) => (
                                    <NavItem key={index}>
                                        <NavLink href={ item.url}>{item.text}</NavLink>
                                    </NavItem>
                                ))} */}
                            </Nav>
                        </Container>
                    </Navbar>
                {/* </Slide> */}
            </header>
        );
    }
}

export default Header;