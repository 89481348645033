import React from "react";
import SocialMediaIcon from "../footer/SocialMediaIcon";

import "./social.css";

const SocialMedia = (props) => {
	const socialMediaAccounts = [
		{
			url: "https://www.linkedin.com/in/alexolivas",
			icon: "fab fa-linkedin",
			cssClass: "linkedin-icon"
		}, {
			url: "https://github.com/alexolivas",
			icon: "fab fa-github",
			cssClass: "github-icon"
		}, {
			url: "https://www.instagram.com/alexolivas11",
			icon: "fab fa-instagram",
			cssClass: "instagram-icon"
		}, {
			url: "https://twitter.com/alex51atlas",
			icon: "fab fa-twitter",
			cssClass: "twitter-icon"
		}, {
			url: "https://www.spotify.com",
			icon: "fab fa-spotify",
			cssClass: "spotify-icon"
		}
	];
	const items = socialMediaAccounts.map((account, index) =>
	<SocialMediaIcon
		key={ index }
		url={ account.url }
		icon={ account.icon }
		cssClass={ account.cssClass }
	/>
);
	return (
		<div className="sticky">
			<ul className="social-network social-circle">
				{ items }
			</ul>
		</div>
	)
};

export default SocialMedia;