import React, { Component } from 'react';
import Typist from 'react-typist';
import './BashShell.css';
import '../../../node_modules/react-typist/dist/Typist.css';

/**
 * This component simulates a bash shell terminal window. For this website it is primarily used
 * to display my job title in a geeky fashion
 */
class BashShell extends Component {

    render() {
        return (
            <div className="shell">
                <Typist avgTypingDelay={190}>$&nbsp;<Typist.Delay ms={900} />{ this.props.text }</Typist>
            </div>
        );
    }
}

export default BashShell;