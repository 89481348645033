import React from "react";
import { Col, Row, Container } from "reactstrap";
import Section from "./Section";

import technicalSkills from "./__mocks__/technicalSkills.json";
import "devicon/devicon.css"

const SkillSection= () => (
    <Section id="skills-section" title="Technical Skills">
        {/* <p className="mb-4 mt-2">This is not a comprehensive list of my technical skills but it does highlight some of the technologies I'm most proficient with.</p> */}
        <Container>
            <Row className="skill-group">
                { technicalSkills.map((skillGroup) => (
                    <Col key={skillGroup.id} md={3}>
                        <h4>{skillGroup.name}</h4>
                        {skillGroup.data.map((skill) => (
                            <span key={skill.name} className="skill">
                                <i className={skill.icon} /> {skill.name}
                            </span>
                        ))}
                    </Col>
                ))}
            </Row>
        </Container>
    </Section>
);

export default SkillSection;
