import PropTypes from "prop-types";
import React from "react";
import { Container, Row } from "reactstrap";

import "./section.scss";

const Section= (props) => (
    <section className={ props.color } { ...props }>
        <Container>
			{/* { props.title && (
				<Row className="justify-content-center">
					<h1 className="section-title">{ props.title }</h1>
				</Row>
			)} */}
			{ props.title && (
				<Row>
					<h1 className="section-title">{ props.title }</h1>
				</Row>
			)}
            <Row>
				{ props.children }
            </Row>
        </Container>
    </section>
);

Section.propTypes = {
	title: PropTypes.string,
	// color: PropTypes.oneOf(["white", "navy", "skyblue"]),
};

// Section.defaultProps = {
// 	color: "white",
// };

export default Section;
