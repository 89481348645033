import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import Home from './home/Home';
import Footer from "./common/footer/Footer";
import Header from "./common/header/Header";
// import About from "./about/About";
import SocialMedia from './common/social/SocialMedia';
// import Portfolio from "./portfolio/Portfolio";

class App extends Component {
    render() {
        // Followed this: https://auth0.com/blog/react-router-4-practical-tutorial/
        const menuItems = [
            {
                text: "home",
                url: "/",
                component: Home
            // }, {
            //     text: "about",
            //     url: "/about",
            //     component: About
            // }, {
            //     text: "portfolio",
            //     url: "/portfolio",
            //     component: Portfolio
            }
        ];

        const routes = menuItems.map((item, index) =>
            <Route key={ index } exact path={ item.url } component={ item.component }/>
        );

        return (
            <div>
                <Header menuItems={ menuItems }/>
                <SocialMedia />
                { routes }
                <Footer/>
            </div>
        );
    }
}

export default App;
