import moment from 'moment';
import React, { Component } from "react";
import Modal from 'react-responsive-modal';
import SocialMediaIcon from './SocialMediaIcon';
import ContactForm from "../forms/ContactForm";
import "./Footer.css";
import { Col, Container, Row } from 'reactstrap';

export default class Footer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            openContactModal: false
        };

        this.openContactMeModal = this.openContactMeModal.bind(this);
        this.closeContactMeModal = this.closeContactMeModal.bind(this);
    }

    openContactMeModal() {
        this.setState({
            openContactModal: true
        });
    }

    closeContactMeModal() {
        this.setState({
            openContactModal: false
        });
    }

    // renderContactMeIcon() {
    //     return (
    //         <li className="nav-item">
    //             <a className="envelope-icon" href="javascript:void(0);"
    //                onClick={ () => this.openContactMeModal() } target="_blank" rel="noopener noreferrer">
    //                 <i className="fas fa-envelope" />
    //             </a>
    //         </li>
    //     );
    // }

    render() {
        const socialMediaAccounts = [
            {
                url: "https://www.linkedin.com/in/alexolivas",
                icon: "fab fa-linkedin",
                cssClass: "linkedin-icon"
            }, {
                url: "https://github.com/alexolivas",
                icon: "fab fa-github",
                cssClass: "github-icon"
            }, {
                url: "https://www.instagram.com/alexolivas11",
                icon: "fab fa-instagram",
                cssClass: "instagram-icon"
            }, {
                url: "https://twitter.com/alex51atlas",
                icon: "fab fa-twitter",
                cssClass: "twitter-icon"
            }, {
                url: "https://www.spotify.com",
                icon: "fab fa-spotify",
                cssClass: "spotify-icon"
            }
        ];

        const items = socialMediaAccounts.map((account, index) =>
            <SocialMediaIcon key={ index } url={ account.url } icon={ account.icon } cssClass={ account.cssClass } />
        );

        return (
            <footer>
                <Container className="text-center">
                    <Row className="mt-5">
                        <Col>
                            <h4>Let's Connect</h4>
                            <ul className="social-network social-circle">
                                { items }
                                {/* { this.renderContactMeIcon() } */}
                            </ul>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="website-details mb-5">
                                <p><i className="fa fa-map-pin" style={{ color: '#D9B310' }} /> Washington DC based full stack software engineer.</p>
                                Copyright <i className="fa fa-copyright" /> { moment().year() }. <strong>Website design, development and deployment by <a href="/">Alex Olivas</a>.</strong>
                            </div>
                        </Col>
                    </Row>
                    <Modal open={ this.state.openContactModal } onClose={ this.closeContactMeModal } center>
                        <ContactForm />
                    </Modal>
                </Container>
            </footer>
        );
    }
}