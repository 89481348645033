import React, { Component } from 'react';

/**
 * This component is responsible for displaying the social media accounts on the footer of my website
 */
class SocialMediaIcon extends Component {

    render() {
        return (
            <li className="nav-item">
                <a className={ this.props.cssClass } href={ this.props.url } target="_blank" rel="noopener noreferrer">
                    <i className={ this.props.icon } />
                </a>
            </li>
        );
    }
}

export default SocialMediaIcon;