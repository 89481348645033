import axios from "axios";

// // For common config
// axios.defaults.headers.post["Content-Type"] = "application/json";

const accessToken = "";
const linkedInAPI = axios.create({
	baseURL: 'https://api.linkedin.com',
	headers: {
		'Authorization': `Bearer ${ accessToken }`,
		'cache-control': 'no-cache',
		'X-Restli-Protocol-Version': '2.0.0',
	},
});

const atlasAPI = axios.create({
	baseURL: process.env.REACT_APP_ATLAS_API,
	headers: {
		'Authorization': `Token ${ process.env.REACT_APP_ATLAS_API_TOKEN }`,
	},
});


export {
	linkedInAPI,
	atlasAPI,
};
