import axios from "axios";
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from "react-router-dom";
import './index.css';
import App from './App';
import registerServiceWorker from './registerServiceWorker';

axios.defaults.baseURL = process.env.REACT_APP_ATLAS_API;
axios.defaults.headers.common['Authorization'] = `Token ${ process.env.REACT_APP_ATLAS_API_TOKEN }`; 

ReactDOM.render(
  <Router>
    <App />
  </Router>, document.getElementById('root'));
registerServiceWorker();
